import image1 from "./images/weatherapp.png";
import image2 from "./images/githubfinder.png";
import image3 from "./images/todos.png";
import image4 from "./images/mimosa.png";
import image5 from "./images/restaurant.png";
import image6 from "./images/github.png";
import shortid from "shortid";

const projData = [
  {
    title: "Weather App",
    id: shortid.generate(),
    img: image1,
    desc: " A responsive (to different screen sizes) website that fetches real time weather information from 8 cities",
    link: "https://segaranweatherapp.netlify.app/",
  },
  {
    title: "Github Finder",
    id: shortid.generate(),
    img: image2,
    desc: "A website to search github users and click on profile to see details",
    link: "https://github-finder34.netlify.app/",
  },
  {
    title: "ToDo List App",
    id: shortid.generate(),
    img: image3,
    desc: "A website where you can maintain a list of things to do",
    link: "https://wondrous-alfajores-d09ce3.netlify.app/",
  },
  {
    title: "Clothing Shop Homepage",
    id: shortid.generate(),
    img: image4,
    desc: "A website where you can maintain a list of things to do",
    link: "https://profound-lolly-ea9674.netlify.app/",
  },
  {
    title: "Restaurant Website Homepage",
    id: shortid.generate(),
    img: image5,
    desc: "A website where you can maintain a list of things to do",
    link: "https://signature-cuisine.netlify.app/",
  },
  {
    title: "My Github PageRestaurant Website Homepage",
    id: shortid.generate(),
    img: image6,
    desc: "A website where you can maintain a list of things to do",
    link: "https://github.com/blsgrn",
  },
];

export default projData;
