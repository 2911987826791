import React from "react";
import Layout from "../components/Layout";
import photo from "../resources/images/cvphoto.jpg";
import {
  FaWindows,
  FaLinux,
  FaHtml5,
  FaReact,
  FaJava,
  FaGitAlt,
  FaGithub,
  FaChessKnight,
} from "react-icons/fa";
import {
  SiJavascript,
  SiCplusplus,
  SiMicrosoftsqlserver,
  SiApplemusic,
} from "react-icons/si";
import { DiMysql } from "react-icons/di";
import { ImBooks } from "react-icons/im";

function Resume() {
  return (
    <Layout>
      <div className="m-20 md:mx-10 sm:mx-4">
        <div className=" min-h-screen flex flex-col justify-center items-center mx-10 md:mx-6 sm:mx-2 xm:mx-0">
          <div className="bg-slate-500 w-full h-2" />
          <div className="bg-gradient-to-b from-amber-50 to-yellow-100 flex flex-col items-center min-h-screen w-full px-14 md:px-6 sm:px-3 mx:px-1 shadow-2xl">
            <div className=" flex md:flex-col w-full">
              <div className="w-2/3 lg:w-full text-4xl md:text-2xl sm:text-lg xm:text-sm tracking-widest text-start flex flex-col">
                <h1 className="px-5 pt-8 pb-2 font-abril font-semibold text-opacity-60 text-lime-600 ">
                  S. SEGARAN
                </h1>
                <h1 className="text-3xl md:text-xl sm:text-base xm:text-xs px-5 text-start font-rubik  text-indigo-400">
                  Full Stack Developer
                </h1>
                <h1 className="text-lg sm:text-sm xm:text-xs px-5 text-start font-mono  text-slate-600">
                  075-795-2581{" "}
                  <span className=" font-extrabold text-indigo-400">| </span>{" "}
                  <span> blsgrn@tutanota.com</span>
                </h1>
              </div>
              <div className="w-1/3 lg:w-full flex justify-center items-center">
                <div className="border-4 border-white rounded-lg m-6 md:m-2">
                  <div className="">
                    <img
                      className="max-h-56 md:max-h-40 sm:max-h-36 flex-1 "
                      src={photo}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-slate-300 w-full px-14  h-0.5" />
            <div className=" flex flex-col w-full">
              <div className="flex sm:flex-col sm:justify-center sm:items-center">
                <div className="w-1/4 flex justify-center ">
                  <div className="flex-1">
                    <h1 className="p-5 md:p-2 mx-2 font-kolker font-medium text-6xl md:text-5xl sm:text-4xl text-indigo-600">
                      Profile
                    </h1>
                  </div>
                </div>
                <div className="w-3/4 flex justify-center items-center ">
                  <div className=" flex-1">
                    <h1 className="p-5 sm:p-2 text-slate-600 font-tek text-2xl md:text-xl sm:text-lg xm:text-sm">
                      <p>
                        A highly motivated and technically skilled Full Stack
                        Developer with a strong foundation in web development,
                        software engineering, and database management.
                        Proficient in developing scalable applications using
                        modern frameworks like ReactJS, TailwindCSS, and DaisyUI
                        while leveraging expertise in MongoDB and Spring Boot
                        for backend services. Experienced in frontend and
                        backend integration, implementing REST APIs, and
                        ensuring efficient data handling with MongoDB Atlas.
                      </p>
                      <br />
                      <p>
                        Key strengths include: Expertise in C# .NET, React,
                        Spring Boot, and MongoDB. Demonstrated ability to design
                        and implement role-based access control and secure
                        systems. Proficiency in testing frameworks, including
                        JUnit and Selenium, to ensure software reliability.
                        Strong commitment to learning and applying emerging
                        technologies, such as AI-assisted automation, to drive
                        business innovation. Skilled at collaborating across
                        teams and presenting technical concepts effectively.
                        Eager to contribute to dynamic software development
                        teams and tackle challenges that enhance user
                        experiences and optimize system performance.
                      </p>
                      <br />
                      <p>
                        Other technical skills I am familiar with are Git
                        versions, Linux systems, MySQL, MongoDB etc.
                      </p>
                    </h1>
                  </div>
                </div>
              </div>
              <div className="bg-slate-300 w-full px-14  h-0.5" />
              <div className="flex sm:flex-col sm:justify-center sm:items-center">
                <div className="w-1/4 flex justify-center ">
                  <div className="flex-1">
                    <h1 className="p-5 sm:p-2 mx-2 font-kolker font-medium text-6xl md:text-5xl sm:text-4xl text-indigo-600">
                      Education
                    </h1>
                  </div>
                </div>
                <div className="w-3/4 md:w-full flex justify-center items-center ">
                  <div className=" flex-1">
                    <h1 className="p-5 sm:p-2 text-slate-600 font-tek text-xl md:text-sm sm:text-xs grid grid-cols-2 ">
                      <div className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                        <h1 className="text-cyan-700 font-semibold">
                          Ordinary Level
                        </h1>
                        <hr />
                        <ul className="list-disc list-inside">
                          <li>Science - D</li>
                          <li>English - D</li>
                          <li>Accounts - D</li>
                          <li>Maths - C</li>
                        </ul>

                        <br />
                        <h1 className="text-cyan-700 font-semibold">
                          Advanced Level
                        </h1>
                        <hr />
                        <ul className="list-disc list-inside">
                          <li>Physics- C</li>
                          <li>Chemistry - S</li>
                          <li>Botany - C</li>
                          <li>Zoology - S</li>
                        </ul>
                      </div>
                      <div className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                        <h1 className="text-cyan-700 font-semibold">
                          CIMA Stage-1
                        </h1>
                        <hr />
                        <ul className="list-disc list-inside">
                          <li>Financial Acc. Fundamentals</li>
                          <li>Cost Acc.</li>
                          <li>Economics</li>
                          <li>Business and IT</li>
                        </ul>

                        <br />
                        <h1 className="text-cyan-700 font-semibold">
                          CIMA Stage-2
                        </h1>
                        <hr />
                        <ul className="list-disc list-inside">
                          <li>Financial Acc.</li>
                          <li>Operational Cost Acc.</li>
                          <li>Management Science</li>
                          <li>Business Law</li>
                        </ul>
                        <br />
                        <h1 className="text-cyan-700 font-semibold">
                          CIMA Stage-3
                        </h1>
                        <hr />
                        <ul className="list-disc list-inside">
                          <li>Business Taxation</li>
                        </ul>
                      </div>

                      <div className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                        <h1>
                          Distinction in Higer Diploma in Software Engineering
                          (Cardiff Metropolitan, ICBT)
                        </h1>
                        <h1>
                          Pursuing BSc in Software Engineering (Cardiff
                          Metropolitan, ICBT)
                        </h1>
                      </div>
                      <div className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                        <h1>Toefle PBT- 577</h1>
                      </div>
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-slate-300 w-full px-14  h-0.5" />
            <div className=" flex flex-col w-full">
              <div className="flex sm:flex-col sm:justify-center sm:items-center">
                <div className="w-1/4 flex justify-center ">
                  <div className="flex-1">
                    <h1 className="p-5 md:p-2 mx-2 font-kolker font-medium text-6xl md:text-5xl sm:text-4xl text-indigo-600">
                      Education Institutions
                    </h1>
                  </div>
                </div>
                <div className="w-3/4 md:w-full flex justify-center items-center ">
                  <div className=" flex-1">
                    <div className="p-5 sm:p-2 text-slate-600 font-tek text-xl md:text-sm sm:text-xs">
                      <div>
                        <p className="px-2 mx-2 text-orange-500">Past</p>
                        <h1 className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                          <p className="text-cyan-700 font-semibold">
                            Grade 1-4 :
                          </p>
                          St. Agnes, Georgetown, Guyana.
                        </h1>
                        <h1 className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                          <p className="text-cyan-700 font-semibold">
                            Grade 7-10 :
                          </p>
                          Isipathana College, Colombo - 05.
                        </h1>
                        <h1 className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                          <p className="text-cyan-700 font-semibold">
                            Advanced Level :
                          </p>
                          St. Joseph's College, Colombo - 10.
                        </h1>
                        <h1 className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                          <p className="text-cyan-700 font-semibold">
                            CIMA (upto stage 3):
                          </p>
                          ABS, Colombo -04.
                        </h1>
                      </div>
                      {/* <div>
                        <p className="px-2 mx-2 text-orange-500">Current</p>
                        <h1 className="m-2 p-2 border-2 border-amber-100 shadow-lg">
                          <p className="text-cyan-700 font-semibold">
                            BSc Software Engineering (Cardiff Metropolitan
                            University):
                          </p>
                          ICBT, Colombo -04.
                        </h1>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-slate-300 w-full px-14  h-0.5" />
            <div className=" flex flex-col w-full">
              <div className="flex sm:flex-col sm:justify-center sm:items-center">
                <div className="w-1/4 flex justify-center ">
                  <div className="flex-1">
                    <h1 className="p-5 md:p-2 mx-2 font-kolker font-medium text-6xl md:text-5xl sm:text-4xl text-indigo-600">
                      Skills
                    </h1>
                  </div>
                </div>
                <div className="w-3/4 md:w-full flex justify-center items-center ">
                  <div className=" flex-1">
                    <div className="p-5 sm:p-2 text-slate-600 font-tek text-center text-2xl md:text-xl  sm:text-lg grid grid-cols-2 xm:grid-cols-1 ">
                      <div className="m-4 p-2 bg-gradient-to-b from-green-200 to-violet-400 transition transform hover:-translate-y-1 hover:shadow-xl max-h-48 max-w-sm shadow-md">
                        <h1 className="text-indigo-700">Operating Systems</h1>
                        <div className="pl-4 text-start font-oswald text-sm">
                          <h1>
                            <div className="flex items-center">
                              <FaWindows />
                              <h1 className="mx-2">Microsoft Windows</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <FaLinux />
                              <h1 className="mx-2">Linux</h1>
                            </div>
                          </h1>
                        </div>
                      </div>
                      <div className="m-4 p-2 bg-gradient-to-b  from-green-200 to-violet-400 transition transform hover:-translate-y-1 hover:shadow-xl shadow-md max-h-48 max-w-sm ">
                        <h1 className="text-indigo-700">
                          Programming Languages
                        </h1>
                        <div className="pl-4 text-start font-oswald text-sm">
                          <h1>
                            <div className="flex items-center">
                              <FaHtml5 />
                              <h1 className="mx-2">HTML/ CSS</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <SiJavascript />
                              <h1 className="mx-2">JavaScript</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <FaReact />
                              <h1 className="mx-2">React</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <SiCplusplus />
                              <h1 className="mx-2">C++</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <FaJava />
                              <h1 className="mx-2">Java</h1>
                            </div>
                          </h1>
                        </div>
                      </div>
                      <div className="m-4 p-2 bg-gradient-to-b  from-green-200 to-violet-400 transition transform hover:-translate-y-1 hover:shadow-xl max-h-48 max-w-sm shadow-md">
                        <h1 className="text-indigo-700">Database</h1>
                        <div className="pl-4 text-start font-oswald text-sm">
                          <h1>
                            <div className="flex items-center">
                              <DiMysql />
                              <h1 className="mx-2">MySQL</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <SiMicrosoftsqlserver />
                              <h1 className="mx-2">SQL Server</h1>
                            </div>
                          </h1>
                        </div>
                      </div>
                      <div className="m-4 p-2 bg-gradient-to-b from-green-200 to-violet-400 transition transform hover:-translate-y-1 hover:shadow-xl max-h-48 max-w-sm shadow-md">
                        <h1 className="text-indigo-700">Version Control</h1>
                        <div className="pl-4 text-start font-oswald text-sm">
                          <h1>
                            <div className="flex items-center">
                              <FaGitAlt />
                              <h1 className="mx-2">Git</h1>
                            </div>
                          </h1>
                          <h1>
                            <div className="flex items-center">
                              <FaGithub />
                              <h1 className="mx-2">GitHub</h1>
                            </div>
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-slate-300 w-full px-14  h-0.5" />
            <div className=" flex flex-col w-full">
              <div className="flex sm:flex-col sm:justify-center sm:items-center">
                <div className="w-1/4 flex justify-center ">
                  <div className="flex-1">
                    <h1 className="p-5 md:p-2 mx-2 font-kolker font-medium text-6xl md:text-5xl sm:text-4xl text-indigo-600">
                      Interests
                    </h1>
                  </div>
                </div>
                <div className="w-3/4 md:w-full flex justify-center items-center ">
                  <div className=" flex-1">
                    <div className="p-5 sm:p-2 text-slate-600 font-tek text-center text-2xl md:text-xl  sm:text-lg grid grid-cols-3 sm:grid-cols-1">
                      <div className="m-4 p-4 h-32 shadow-md bg-gradient-to-b from-green-200 to-transparent transition transform hover:-translate-y-1 hover:shadow-xl">
                        <ImBooks size={40} color={"#726146"} />
                        <h1 className="mt-2">Philosopy</h1>
                      </div>
                      <div className="m-4 p-4 h-32 shadow-md bg-gradient-to-b from-green-200 to-transparent transition transform hover:-translate-y-1 hover:shadow-xl">
                        <SiApplemusic size={40} color={"#726146"} />
                        <h1 className="mt-2">Music</h1>
                      </div>
                      <div className="m-4 p-4 h-32 shadow-md bg-gradient-to-b from-green-200 to-transparent transition transform hover:-translate-y-1 hover:shadow-xl">
                        <FaChessKnight size={40} color={"#726146"} />
                        <h1 className="mt-2">Chess</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resume;
